import { Ability } from '@casl/ability'
import { Storage } from '../../services/Service'
import { initialAbility } from './initialAbility'
const userData = Storage.get("auth")

//  Read ability from localStorage
// * Handles auto fetching previous abilities if already logged in user
// ? You can update this if you store user abilities to more secure place
// ! Anyone can update localStorage so be careful and please update this
let existingAbility = []
if (userData && userData.role === 5 && userData.permissions.length > 0) {
  existingAbility = userData.permissions.map((item) => {
    return {
      action: 'manage',
      subject: item.permissionSlug
    }
  })
  existingAbility.push({
    action: 'manage',
    subject: 'dashboard'
  })
  console.log("existingAbility", existingAbility)
} else {
  existingAbility.push({
    action: 'manage',
    subject: 'all'
  })
}

export default new Ability(existingAbility || initialAbility)
