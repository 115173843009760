// ** Initial State
const initialState = {
    recipientList: [],
    isFetching: false
}

const recipients = (state = initialState, action) => {
    switch (action.type) {
        case 'GET_RECIPIENT_LIST':
            return { ...state, recipientList: action.payload, isFetching: action.isFetching }   
        default:
            return { ...state }
    }
}

export default recipients