const initialState = {
    storesData: [],
    isFetching: false,
    storeData: [],
    isStoreFetching: false,
    transactionData: [],
    isTransFetching: false,
    storeDetails: [],
    isDetailsFetching: false
}

const stores = (state = initialState, action) => {
    switch (action.type) {
        case 'GET_ALL_STORES':
            return { ...state, storesData: action.payload, isFetching: action.isFetching }
        case 'GET_STORE_ID':
            return { ...state, storeData: action.payload, isStoreFetching: action.isFetching }
        case 'GET_STORE_TRANSACTIONS':
            return { ...state, transactionData: action.payload, isTransFetching: action.isFetching }
        case 'GET_STORE_DETAILS':
            return { ...state, storeDetails: action.payload, isDetailsFetching: action.isFetching }
        default:
            return { ...state }
    }
}

export default stores