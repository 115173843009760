const initialState = {
    suppliersData: [],
    isFetching: false
}

const suppliers = (state = initialState, action) => {
    switch (action.type) {
        case 'GET_ALL_SUPPLIERS':
            return { ...state, suppliersData: action.payload, isFetching: action.isFetching }
        default:
            return { ...state }
    }
}

export default suppliers