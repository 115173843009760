const initialState = {
    packageTypeData: [],
    storeFeaturesData: [],
    supplierFeaturesData: [],
    packagesData: [],
    isFetching: false,
    isTypeFetching: false,
    isStoreFetching: false,
    isSupplierFetching: false
}

const packages = (state = initialState, action) => {
    switch (action.type) {
        case 'GET_ALL_PACKAGE_TYPE':
            return { ...state, packageTypeData: action.payload, isTypeFetching: action.isFetching }
        case 'GET_ALL_STORE_FEATURES':
            return { ...state, storeFeaturesData: action.payload, isStoreFetching: action.isFetching }
        case 'GET_ALL_SUPPLIER_FEATURES':
            return { ...state, supplierFeaturesData: action.payload, isSupplierFetching: action.isFetching }
        case 'GET_ALL_PACKAGES':
            return { ...state, packagesData: action.payload, isFetching: action.isFetching }
        default:
            return { ...state }
    }
}

export default packages