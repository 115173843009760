// ** Initial State
const initialState = {
    millsData: [],
    storeData: [],
    subCategoryData: [],
    itemsData: [],
    imageData: [],
    faqData: [],
    packageFaqData: [],
    categoriesData: [],
    contentData: [],
    packageData: [],
    packageItineraryData: [],
    packageSettingData: [],
    settingsData: null,
    faqDataId: null,
    profileData: [],
    userProfileData: [],
    isImagesFetching: false,
    isPackageFaqFetching: false,
    isCategoriesFetching: false,
    isSubCategoryFetching: false,
    isItemFetching: false,
    isContentFetching: false,
    isFaqFetching: false,
    isPackageFetching: false,
    isSettingFetching: false,
    isMillsFetching: false,
    isStoreFetching: false,
    isFetching: false,
    isProfileFetching: false,
    jwtData: [],
    isJwtFetching: false,
    componentsData: [],
    isComponentFetching: false,
    assetTypesData: [],
    isTypeFetching: false,
    assetsData: [],
    isAssetFetching: false,
    patternsData: [],
    isPatternFetching: false,
    backgroundData: [],
    isBackgroundFetching: false,
    updatesData: [],
    isUpdateFetching: false,
    latestUpdateData: [],
    isLatestFetching: false,
    modelsBySubData: [],
    isModelsFetching: false,
    getPackageNotificationData: [],
    isPackageNotificationFetching: false,
    getAllClientPatterns: [],
    isClientPatternsFetching: false,
    getAllStoreModels: [],
    isStoreModelsFetching: false,
    getAllProductCategory: [],
    isProductCategoryFetching: false,
    getAllProductSubCategory: [],
    isProductSubCategoryFetching: false,
    getAllClients: [],
    isClientsFetching: false,
    getAllProductItems: [],
    isProductItemsFetching: false,
    getAllProductTypeData: [],
    isProductTypeFetching: false,
    getAllOrderList: [],
    isOrdersFetching: false,
    getAllOrderDetails: [],
    isOrderDetailsFetching: false,
    getAllOrderTransactionDetails: [],
    isOrderTransactionDetailsFetching: false,
    getAllOrderRefundDetails: [],
    isOrderRefundDetailsFetching: false,
    getAllStorePackageDetails: [],
    isStorePackageDetailsFetching: false,
    getAllProductTypeDetails: [],
    isProductTypeDetailsFetching: false,
    getAllPermissionDetails: [],
    isPermissionDetailsFetching: false,
    getAllRolesDetails: [],
    isRolesDetailsFetching: false
}

const global = (state = initialState, action) => {
    switch (action.type) {
        case 'GET_ALL_MILLS':
            return { ...state, millsData: action.payload, isMillsFetching: action.isFetching }
        case 'GET_STORE_LIST':
            return { ...state, storeData: action.payload, isStoreFetching: action.isFetching }
        case 'GET_ALL_CATEGORIES':
            return { ...state, categoriesData: action.payload, isCategoriesFetching: action.isFetching }
        case 'GET_ALL_SUBCATEGORIES':
            return { ...state, subCategoryData: action.payload, isSubCategoryFetching: action.isFetching }
        case 'GET_ALL_ITEMS':
            return { ...state, itemsData: action.payload, isItemFetching: action.isFetching }
        case 'UPDATE_PROFILE':
            return { ...state, profileData: action.payload, isFetching: action.isFetching }
        case 'GET_ALL_IMAGES':
            return { ...state, imageData: action.payload, isImagesFetching: action.isFetching }
        case 'GET_ALL_FAQ':
            return { ...state, faqData: action.payload, isFaqFetching: action.isFetching }
        case 'GET_PACKAGE_FAQ':
            return { ...state, packageFaqData: action.payload, isPackageFaqFetching: action.isFetching }
        case 'GET_ALL_CONTENT':
            return { ...state, contentData: action.payload, isContentFetching: action.isFetching }
        case 'GET_PACKAGE_ID':
            return { ...state, packageData: action.payload, isPackageFetching: action.isFetching }
        case 'GET_PACKAGE_ITINERARY':
            return { ...state, packageItineraryData: action.payload, isPackageFetching: action.isFetching }
        case 'GET_SETTINGS':
            return { ...state, settingsData: action.payload, isSettingFetching: action.isFetching }
        case 'GET_FAQ_DATA':
            return { ...state, faqDataId: action.payload, isFaqFetching: action.isFetching }
        case 'GET_PACKAGE_SETTINGS':
            return { ...state, packageSettingData: action.payload, isFetching: action.isFetching }
        case 'GET_USER_PROFILE':
            return { ...state, userProfileData: action.payload, isProfileFetching: action.isFetching }
        case 'GET_JWT_TOKEN':
            return { ...state, jwtData: action.payload, isJwtFetching: action.isFetching }
        case 'GET_ALL_COMPONENTS':
            return { ...state, componentsData: action.payload, isComponentFetching: action.isFetching }
        case 'GET_ALL_TYPES':
            return { ...state, assetTypesData: action.payload, isTypeFetching: action.isFetching }
        case 'GET_ALL_ASSETS':
            return { ...state, assetsData: action.payload, isAssetFetching: action.isFetching }
        case 'GET_ALL_PATTERNS':
            return { ...state, patternsData: action.payload, isPatternFetching: action.isFetching }
        case 'GET_ALL_BACKGROUND':
            return { ...state, backgroundData: action.payload, isBackgroundFetching: action.isFetching }
        case 'GET_ALL_UPDATES':
            return { ...state, updatesData: action.payload, isUpdateFetching: action.isFetching }
        case 'GET_LATEST_UPDATE':
            return { ...state, latestUpdateData: action.payload, isLatestFetching: action.isFetching }
        case 'GET_MODEL_BY_SUB':
            return { ...state, modelsBySubData: action.payload, isModelsFetching: action.isFetching }
        case 'GET_PACKAGE_NOTIFICATION_DATA':
            return { ...state, getPackageNotificationData: action.payload, isPackageNotificationFetching: action.isFetching }
        case 'GET_ALL_CLIENT_PATTERNS':
            return { ...state, getAllClientPatterns: action.payload, isClientPatternsFetching: action.isFetching }
        case 'GET_ALL_STORE_MODELS':
            return { ...state, getAllStoreModels: action.payload, isStoreModelsFetching: action.isFetching }
        case 'GET_ALL_PRODUCT_CATEGORY':
            return { ...state, getAllProductCategory: action.payload, isProductCategoryFetching: action.isFetching }
        case 'GET_ALL_PRODUCT_SUB_CATEGORY':
            return { ...state, getAllProductSubCategory: action.payload, isProductSubCategoryFetching: action.isFetching }
        case 'GET_ALL_CLIENTS':
            return { ...state, getAllClients: action.payload, isClientsFetching: action.isFetching }
        case 'GET_ALL_PRODUCT_ITEMS':
            return { ...state, getAllProductItems: action.payload, isProductItemsFetching: action.isFetching }
        case 'GET_ALL_PRODUCT_TYPE_DATA':
            return { ...state, getAllProductTypeData: action.payload, isProductTypeFetching: action.isFetching }
        case 'GET_ALL_ORDER_LIST':
            return { ...state, getAllOrderList: action.payload, isOrdersFetching: action.isFetching }
        case 'GET_ALL_ORDER_DETAILS':
            return { ...state, getAllOrderDetails: action.payload, isOrderDetailsFetching: action.isFetching }
        case 'GET_ALL_ORDER_TRANSACTION_DETAILS':
            return { ...state, getAllOrderTransactionDetails: action.payload, isOrderTransactionDetailsFetching: action.isFetching }
        case 'GET_ALL_ORDER_REFUND_DETAILS':
            return { ...state, getAllOrderRefundDetails: action.payload, isOrderRefundDetailsFetching: action.isFetching }
        case 'GET_ALL_STORE_PACKAGE_DETAILS':
            return { ...state, getAllStorePackageDetails: action.payload, isStorePackageDetailsFetching: action.isFetching }
        case 'GET_ALL_PRODUCT_TYPE_DETAILS':
            return { ...state, getAllProductTypeDetails: action.payload, isProductTypeDetailsFetching: action.isFetching }
        case 'GET_ALL_PERMISSION_DETAILS':
            return { ...state, getAllPermissionDetails: action.payload, isPermissionDetailsFetching: action.isFetching }
        case 'GET_ALL_ROLES_DETAILS':
            return { ...state, getAllRolesDetails: action.payload, isRolesDetailsFetching: action.isFetching }
        default:
            return { ...state }
    }
}

export default global